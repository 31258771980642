<template>
  <v-container class="col-9">
    <v-card>
      <v-card-title>Analyses</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Filename</th>
                <th class="text-left">Filehash</th>
                <th class="text-left">Last analysis</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in files" :key="file.hash">
                <td>
                  <router-link :to="'analysis/' + file.hash">{{
                    file.name
                  }}</router-link>
                </td>
                <td>
                  <pre>{{ file.hash }}</pre>
                </td>
                <td>{{ formatDate(file.analysis_time) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Analyses",
  data() {
    return {
      files: null,
    };
  },
  mounted() {
    axios
      .get(this.$api_base_url + "/analysis")
      .then((response) => (this.files = response.data.files));
  },
  methods: {
    formatDate(value) {
      return moment.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>